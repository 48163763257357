import { Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { generateTestId } from 'utils/test-id.utils';

import { Collection } from 'types/storefront.types';

import { LanguageWrap, Line } from './LanguageItem.style';

interface Props {
  item: Collection;
  onLanguageClick(): void;
}

const LanguageItem: React.VFC<Props> = ({ item, onLanguageClick }) => {
  const intl = useIntl();
  return (
    <Stack direction="row" alignItems="center">
      <LanguageWrap disableRipple onClick={() => onLanguageClick()} {...generateTestId(item.title)}>
        <Typography variant="h2" lineHeight="50px">
          {item.title}
        </Typography>
        <Line className="line" preserveAspectRatio="none" />
      </LanguageWrap>
      {item.metafield?.value === 'true' && (
        <Chip sx={{ ml: 4 }} label={intl.formatMessage({ id: 'label.new' })} color="primary" />
      )}
    </Stack>
  );
};

export default LanguageItem;
