import { Grid } from '@mui/material';
import React from 'react';

import LanguageItem from '../LanguageItem';
import { CommonProps } from './types';

const Mobile: React.FC<CommonProps> = ({ languages, onLanguageClick }) => {
  return (
    <Grid container sx={{ display: { md: 'none' } }}>
      {languages.map(({ node }) => (
        <Grid key={node.id} item xs={12}>
          <LanguageItem item={node} onLanguageClick={() => onLanguageClick(node)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Mobile;
