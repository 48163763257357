import React from 'react';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { CommonProps } from './types';

const LanguageList: React.FC<CommonProps> = ({ onLanguageClick, languages }) => {
  return (
    <>
      <Mobile onLanguageClick={onLanguageClick} languages={languages} />
      <Desktop onLanguageClick={onLanguageClick} languages={languages} />
    </>
  );
};

export default LanguageList;
