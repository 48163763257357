import { Box, Grid } from '@mui/material';
import { chunk } from 'lodash';
import React from 'react';

import LanguageItem from '../LanguageItem';
import { CommonProps } from './types';

const Desktop: React.FC<CommonProps> = ({ languages, onLanguageClick }) => {
  const sortedLanguages = () => {
    const sort = languages.sort((a, b) => a.node.title.localeCompare(b.node.title));

    return chunk(sort, Math.ceil(sort.length / 3));
  };
  return (
    <Grid
      container
      columnSpacing={0}
      columnGap={0}
      rowSpacing={4}
      sx={{ display: { xs: 'none', md: 'flex' } }}
    >
      {sortedLanguages().map((group, index) => (
        <Grid sx={{ pl: 0 }} key={index} item md={4} rowGap={0} mb={4}>
          {group.map(({ node }) => (
            <Box key={node.id} mb={{ md: 10 }}>
              <LanguageItem item={node} onLanguageClick={() => onLanguageClick(node)} />
            </Box>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default Desktop;
