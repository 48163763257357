import { css, keyframes } from '@emotion/react';
import { Button, styled } from '@mui/material';

import { ImgScribbleLine } from 'components/@icons';

const grow = css(keyframes`
  0% { 
    transform: scaleX(0.0);
    -webkit-transform: scaleX(0.0);
  }
  25% { 
    transform: scaleX(0.25);
    -webkit-transform: scaleX(0.25);
  }
  50% { 
    transform: scaleX(0.50);
    -webkit-transform: scaleX(0.50);
    
  }
  75% {
    transform: scaleX(0.75);
    -webkit-transform: scaleX(0.75);
  }
  100% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
  }
`);

export const Line = styled(ImgScribbleLine)`
  width: 0;
  height: 8px;
  fill: ${({ theme }) => theme.palette.blue[200]};
  display: 'none';
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: 'block';
  }
`;

export const LanguageWrap = styled(Button)(({ theme }) => ({
  padding: 0,
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    display: 'inline-block',
    width: '-webkit-min-content',

    '&:hover': {
      '.line': {
        width: '100%',
        transformOrigin: 'left',
        animation: `${grow} .1s ease-in-out 1`,
        '-webkit-animation-name': `${grow}`,
        '-webkit-animation-duration': '.1s',
        '-webkit-animation-timing-function': 'ease-in-out',
        '-webkit-animation-fill-mode': 'forwards',
        '-webkit-animation-iteration-count': 1,
        '-webkit-animation-transform-origin': 'left',
      },
    },
  },
}));
