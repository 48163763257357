import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Box, Container, Typography } from '@mui/material';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { GetStaticProps } from 'next/types';
import { FormattedMessage, useIntl } from 'react-intl';

import { Routes } from 'constants/routes.constants';
import { useOrderFlow } from 'hooks';
import { getCollections, QueryKeys, useCollections, useProfile, useTeacher } from 'queries';
import { CustomCollection } from 'types/collection.types';

import { InfoBox } from 'components/@common';
import { LanguageList } from 'components/@languages';
import { StepContainer } from 'components/@steps';

const Home = () => {
  // prefetch user info when he's logged in
  useProfile();
  useTeacher();
  const intl = useIntl();
  const { data } = useCollections();
  const router = useRouter();
  const { updateOrder, order, setActiveStep } = useOrderFlow();
  const sendToGTM = useGTMDispatch();
  const handleLanguageClick = (collection: CustomCollection) => {
    setActiveStep(order.activeStep + 1);

    if (!!collection.products.edges.length) {
      sendToGTM({ event: 'language_select', value: collection.products.edges[0].node.iso?.value });
    }

    router.push({ pathname: Routes.Language, query: { language: collection.handle } });
    updateOrder({ collection });
  };

  return (
    <>
      <Box mb={10}>
        <StepContainer activeStep={0}>
          <Typography variant="h3" mb={{ xs: 2, md: 10 }}>
            <FormattedMessage id="order_flow.step.language.title" />
          </Typography>
          <LanguageList languages={data?.edges || []} onLanguageClick={handleLanguageClick} />
        </StepContainer>
      </Box>
      <Container sx={{ px: { xs: 0 } }}>
        <InfoBox
          text="info_box.text.language"
          link={{
            text: intl.formatMessage({ id: 'info_box.call_to_action' }),
            url: intl.formatMessage({ id: 'info_box.link.language' }),
          }}
        />
      </Container>
    </>
  );
};

interface StaticProps {
  dehydratedState: DehydratedState;
}

export const getStaticProps: GetStaticProps<StaticProps> = async ({ locale }) => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(QueryKeys.collections.list(locale), () => getCollections(locale));

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 300, // Revalidate after 5 minutes
  };
};

export default Home;
