import {
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

interface Props {
  activeStep: number;
  steps: string[];
}

const StepIndicator: React.VFC<Props> = ({ activeStep, steps }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  return sm ? (
    <Stack alignItems="flex-end">
      <Typography variant="body2">{`${steps[activeStep]} ${activeStep + 1}/${
        steps.length
      }`}</Typography>
    </Stack>
  ) : (
    <Stepper activeStep={activeStep}>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepIndicator;
